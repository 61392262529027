import Vue from 'vue'
import App from './App.vue'
import router from '@/router/router.js'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'fork-awesome/css/fork-awesome.css';
import {Dialog} from "vant";
import {Toast} from 'vant';
import {Popup} from 'vant';
import qy from '@/common/qy.js';
import tool from '@/common/tool.js';
import JkIm from '@/common/JkIm.js';
import ImUtil from '@/common/ImUtil.js';
import mapUtil from '@/common/mapUtil.js';
import storage from '@/common/storage.js';
import underscore from '@/common/underscore.js';
import wxUtil from '@/common/wxUtil.js';
import axios from 'axios' //引入axios

import topNav from "@/components/topNav";
import Meta from "vue-meta";
import Video from "video.js";
import "video.js/dist/video-js.css";
import $ from "jquery";
import BaiduMap from 'vue-baidu-map';

// 设置百度地图API密钥（必须）

 
Vue.use(BaiduMap,{
  ak: 'uQEgWIhWATWI5PA9b9Kmis0yCGe6tIQM'	
})

Vue.prototype.video = Video;
Vue.use(Meta);

Vue.component("qy-top-nav", topNav);
Vue.prototype.$axios = axios; //把axios挂载到vue上
Vue.use(Vant);
Vue.use(Popup);
Vue.prototype.Dialog = Dialog;
Vue.prototype.Toast = Toast;
Vue.prototype.emptyImg = require("@/assets/images/empty.png");
Vue.prototype.qy = qy;
Vue.prototype.tool = tool;
Vue.prototype._ = underscore;
Vue.prototype.$ = $;
Vue.prototype.mapUtil = mapUtil;

Vue.prototype.storage = storage;
Vue.prototype.JkIm = JkIm;
Vue.prototype.ImUtil = ImUtil;
ImUtil.connect();
for (let k in qy) {
	Vue.prototype[k] = qy[k];
}

for (let k in wxUtil) {
	Vue.prototype[k] = wxUtil[k];
}



Vue.prototype.openAliPay = function(url) {
	if (this.isWeiXinBrower()) {
		return false;
	}
	window.open("alipays://platformapi/startapp?saId=10000007&qrcode=" + url);
	return true;
}

//请求失败不处理
Vue.prototype.setSilent = function() {
	storage.set("silent", true);
};
Vue.config.productionTip = false;
Vue.prototype.debugAlert=function(data){
	
	if(location.hostname.indexOf("local")>-1||"17717373576"==qy.getUserPhone()){
		alert(JSON.stringify(data))
	}
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')