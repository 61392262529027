<template>
  <div id="jkShopApp">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>  
  </div>
</template>

<script type="text/javascript">
  import "@/common/common.css";
  export default {
    metaInfo: {
        title: "福鲸商城",
        meta: [
          { name: "viewport", content: "width=device-width, initial-scale=1.0,user-scalable=no" },
          { name: "description", content: "页面描述" },
        ],
      }
  }
</script>
<style lang="scss" scoped="">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
  width:100%;
  height:100vh;
  overflow:auto;
}


</style>
