import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    imageUrl:'http://127.0.0.1',
    state: {
        goodsList: [{
            goodsImg: require('@/assets/images/disk.jpg'),
            goodsName: '金士顿固态硬盘',
            goodsDesc: '金士顿(Kingston)A400 SSD固态硬盘台式机笔记本 SATA3.0接口 固态硬盘480G非500G(游戏推荐)',
            goodsPrice: 299
        }, {
            goodsImg: require('@/assets/images/erji.jpg'),
            goodsName: '漫步者（EDIFIER）耳机 ',
            goodsDesc: '漫步者（EDIFIER）H180Plus 半入耳式有线耳机 手机耳机 音乐耳机 3.5mm接口 电脑笔记本手机适用',
            goodsPrice: 99
        }, {
            goodsImg: require('@/assets/images/yinxiang.jpg'),
            goodsName: '迷你小音箱',
            goodsDesc: '电脑音箱台式机家用桌面迷你小音箱USB 迷你小音箱',
            goodsPrice: 58
        }, {
            goodsImg: require('@/assets/images/mouse.jpg'),
            goodsName: "罗技鼠标",
            goodsDesc: '罗技（Logitech）M100r 有线鼠标 大手鼠标 商务办公鼠标 家用对称鼠标 企业采购 黑色',
            goodsPrice: 45
        }, {
            goodsImg: require('@/assets/images/erji.jpg'),
            goodsName: '漫步者（EDIFIER）耳机 ',
            goodsDesc: '漫步者（EDIFIER）H180Plus 半入耳式有线耳机 手机耳机 音乐耳机 3.5mm接口 电脑笔记本手机适用',
            goodsPrice: 99
        }, {
            goodsImg: require('@/assets/images/yinxiang.jpg'),
            goodsName: '迷你小音箱',
            goodsDesc: '电脑音箱台式机家用桌面迷你小音箱USB 迷你小音箱',
            goodsPrice: 58
        }, {
            goodsImg: require('@/assets/images/mouse.jpg'),
            goodsName: "罗技鼠标",
            goodsDesc: '罗技（Logitech）M100r 有线鼠标 大手鼠标 商务办公鼠标 家用对称鼠标 企业采购 黑色',
            goodsPrice: 45
        }],
        addressList: [{
                id: '1',
                name: '张三',
                tel: '13000000000',
                address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
                isDefault: true,
            },
            {
                id: '2',
                name: '李四',
                tel: '1310000000',
                address: '浙江省杭州市拱墅区莫干山路 50 号',
            },
            {
                id: '3',
                name: '王五',
                tel: '1320000000',
                address: '浙江省杭州市滨江区江南大道 15 号',
            }
        ],
        shopCartList:[{
                    shopName: "晶科电子",
                    isChecked: false,
                    goodsList: [{
                            goodsId: 1,
                            goodsName: "硬盘",
                            goodsDesc: "E20普通版 传输速度200MB/s 三年质保",
                            goodsImg: require("@/assets/images/disk.jpg"),
                            goodsPrice: 120,
                            goodsNum: 1,
                            isChecked: false
                        },
                        {
                            goodsId: 2,
                            goodsName: "硬盘",
                            goodsImg: require("@/assets/images/disk.jpg"),
                            goodsDesc: "E30高速版 传输速度520MB/s 三年质保",
                            goodsPrice: 236,
                            goodsNum: 2,
                            isChecked: false
                        }
                    ]
                },
                {
                    shopName: "天天科技",
                    isChecked: false,
                    goodsList: [{
                            goodsId: 1,
                            goodsName: "蓝牙耳机L1288",
                            goodsImg: require("@/assets/images/erji.jpg"),
                            goodsDesc: "真蓝牙耳机,充电10分钟,听歌4小时",
                            goodsNum: 1,
                            goodsPrice: 68,
                            isChecked: false
                        },
                        {
                            goodsId: 2,
                            goodsName: "蓝牙耳机LK256",
                            goodsImg: require("@/assets/images/erji.jpg"),
                            goodsDesc: "真蓝牙耳机,充电10分钟,听歌4小时,HIFI影院",
                            goodsPrice: 236,
                            goodsNum: 1,
                            isChecked: false
                        }
                    ]

                }],
                orderList:[{
                    shopName: "晶科电子",
                    isChecked: false,
                    goodsList: [{
                            goodsId: 1,
                            goodsName: "硬盘",
                            goodsRemark: "E20普通版 传输速度200MB/s 三年质保",
                            goodsImg: require("@/assets/images/disk.jpg"),
                            goodsPrice: 120,
                            goodsNum: 1,
                            status:0,
                            isChecked: false
                        },
                        {
                            goodsId: 2,
                            goodsName: "硬盘",
                            goodsImg: require("@/assets/images/disk.jpg"),
                            goodsRemark: "E30高速版 传输速度520MB/s 三年质保",
                            goodsPrice: 236,
                            goodsNum: 2,
                            isChecked: false
                        }
                    ]
                },
                {
                    shopName: "天天科技",
                    isChecked: false,
                    goodsList: [{
                            goodsId: 1,
                            goodsName: "蓝牙耳机L1288",
                            goodsImg: require("@/assets/images/erji.jpg"),
                            goodsRemark: "真蓝牙耳机,充电10分钟,听歌4小时",
                            goodsNum: 1,
                            goodsPrice: 68,
                            status:1,
                            isChecked: false
                        },
                        {
                            goodsId: 2,
                            goodsName: "蓝牙耳机LK256",
                            goodsImg: require("@/assets/images/erji.jpg"),
                            goodsRemark: "真蓝牙耳机,充电10分钟,听歌4小时,HIFI影院",
                            goodsPrice: 236,
                            goodsNum: 1,
                            isChecked: false
                        }
                    ]

                }]
    },
    mutations: {},
    actions: {},
    modules: {}
})