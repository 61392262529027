import request from "@/utils/request";
import wx from 'weixin-js-sdk';
export default {
	refreshWxConfig(pageWx,callback, apiList) {
		// setTimeout(()=>{
			let url=encodeURIComponent(location.href.split('#')[0]);
			request.get("api/wx/jsApi?url=" + url).then(rs => {
				if (rs.success && rs.data) {
					this.updatetWxConfig(pageWx,rs.data,callback,apiList);
				}
			});
		// },400);
	},
	updatetWxConfig(pageWx,wxConfig, callback, apiList) {
		if (!wxConfig) {
			return;
		}
		wxConfig.debug = false;
		wxConfig.jsApiList = [
			'hideMenuItems',
			'showMenuItems',
			'updateAppMessageShareData',
			'updateTimelineShareData'
		].concat(apiList || []);
		// this.debugAlert(wxConfig)
		pageWx.config(wxConfig);
		pageWx.ready(() => {
			wx.showMenuItems({
				menuList:["menuItem:share:appMessage","menuItem:share:timeline"]
			});
			setTimeout(()=>{
				callback(pageWx)
			},400);
		});
		pageWx.error((res)=>{
			// this.debugAlert(res)
		});	

	},
	hideShare(){
		try{
			this.refreshWxConfig(wx,()=>{
				wx.hideMenuItems({
					menuList:["menuItem:share:appMessage","menuItem:share:timeline"]
				});
			})
		}catch(e){
			this.debugAlert(e);
		}
	},
	isInWx() {
		return navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1;
	},
	isWeiXinBrower() {
		var ua = window.navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == 'micromessenger') {
			return true;
		} else {
			return false;
		}
	},
	openWxMiniPay(params) {
		let url = '/pages/spay/spay?';
		params.msgType="payShopOrder";
		if (params) {
			let isFirst = true;
			for (let k in params) {
				if (isFirst) {
					isFirst = false;
				} else {
					url += '&';
				}
				url += k + "=" + encodeURIComponent(params[k]);
			}
		}
		// this.debugAlert(wx.miniProgram)
		/* wx.miniProgram.postMessage({
			data:params
		}); */
		wx.miniProgram.navigateTo({
			url: url
		})
	},
	
	openWxH5Pay(payUrl, callbackUrl) {
		var that = this;
		if (payUrl.indexOf("http") == 0) {
			location.href = payUrl;
		} else {
			try {
				let payReqParam = JSON.parse(payUrl);
				if (typeof WeixinJSBridge == "undefined") {
					that.Toast({
						type: "fail",
						message: "请使用微信打开商城!",
						onClose: function() {
							that.$router.go(-1);
						}
					});
					return;
				}
				WeixinJSBridge.invoke('getBrandWCPayRequest', payReqParam, function(res) {
					let tipMsg = "支付失败",
						type = "fail";
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						type = "success";
						tipMsg = "支付成功";
					} else if (res.err_msg == "get_brand_wcpay_request:cancel ") {
						tipMsg = "支付已取消";
					}

					that.Toast({
						type: "fail",
						message: tipMsg,
						duration: 1500,
						onClose: function() {
							if (callbackUrl) {
								that.$router.push({
									path: callbackUrl,
									replace: true
								})
							}

						}
					})
				});
			} catch (e) {
				location.href = payUrl;
			}
		}
	},
	isInWxMiniPro(callback) {
		callback = callback || function() {};
		if (!this.isInWx()) {
			callback(false);
		}
		wx.miniProgram.getEnv(function(res) {
			if (res.miniprogram) {
				// 小程序环境下逻辑
				//Toast("小程序环境")
				callback(true);
			} else {
				//Toast("H5环境")
				callback(false);
			}
		});
	}
}