export default {
    set: function(key, value) {
        window.localStorage.setItem(key, value);
    },
    get: function(key,defaultVal) {
        return window.localStorage.getItem(key)||defaultVal;
    },
    remove: function(key) {
        window.localStorage.removeItem(key);
    },
    pop:function(key,defaultVal){
        let val=this.get(key);
        this.remove(key);
        return val||defaultVal;
    },
    clear: function() {
        window.localStorage.clear();
    }
};