import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import('../views/home/home')
	}, {
		path: '/index',
		name: 'Home',
		component: () => import('../views/home/home')
	},
	{
		path: '/more',
		name: 'Home',
		component: (resolve) => require(['@/views/atpage/atpage'], resolve)
	},
	{
		path: '/message',
		name: 'Message',
		component: () => import('../views/message/message')
	},
	{
		path: '/shopList',
		name: 'ShopList',
		component: () => import('../views/shop/shopList')
	},
	{
		path: '/goodsCategory',
		name: 'GoodsCategory',
		component: () => import('../views/goodsCategory/goodsCategory')
	},
	{
		path: '/shoppingCart',
		name: 'ShoppingCart',
		component: () => import('../views/shoppingCart/shoppingCart')
	},
	{
		path: '/mine',
		name: 'Mine',
		component: (resolve) => require(['@/views/mine/mine'], resolve),
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/About')
	},
	{
		path: '/favorite',
		name: 'Favorite',
		component: () => import('../views/mine/favorite')
	},
	{
		path: '/footmark',
		name: 'Footmark',
		component: () => import('../views/mine/footmark')
	},
	{
		path: '/address',
		name: 'Address',
		component: () => import('../views/address/address')

	},
	{
		path: '/address/edit',
		name: 'AddressEdit',
		alias: "/address/add",
		component: () => import('../views/address/addressEdit')
	}, {
		path: '/address/select',
		name: 'AddressSelect',
		component: () => import('../views/address/addressSelect')
	},
	{
		path: '/goodsInfo',
		name: 'GoodsInfo',
		component: () => import('../views/goodsInfo/goodsInfo')
	},
	{
		path: '/goodsComment',
		name: 'GoodsComment',
		component: () => import('../views/goodsInfo/goodsComment'),
	},
	{
		path: '/goodsList',
		name: 'GoodsList',
		component: () => import('../views/goodsList/goodsList')
	},
	{
		path: '/orderList',
		name: 'OrderList',
		component: () => import('../views/order/orderList')
	},
	{
		path: '/orderDetail',
		name: 'OrderDetail',
		component: () => import('../views/order/orderDetail')
	}, {
		path: '/orderAdd',
		name: 'orderAdd',
		component: () => import('../views/order/orderAdd')
	},
	{
		path: '/payGoodsOrder',
		name: 'PayGoodsOrder',
		component: () => import('../views/order/orderPay'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/login/login')
	},
	{
		path: '/smsLogin',
		name: 'SmsLogin',
		component: () => import('../views/login/smsLogin')
	},
	{
		path: '/password',
		name: 'Password',
		component: () => import('../views/password/passwordManage'),
	},
	{
		path: '/editPwd',
		name: 'EditPwd',
		component: () => import('../views/password/passwordEdit'),
	},
	{
		path: '/forgetPwd',
		name: 'ForgetPwd',
		component: () => import('../views/password/passwordForget'),
	},
	{
		path: '/addComment',
		name: 'AddComment',
		component: () => import('../views/order/comment'),
	},
	{
		path: '/afterSale',
		name: 'AfterSale',
		component: () => import('../views/order/afterSale'),
	},
	{
		path: '/balanceLog',
		name: 'BalanceLog',
		component: () => import('../views/mine/balanceLog'),
	},
	{
		path: '/myAfterSale',
		name: 'MyAfterSale',
		component: () => import('../views/mine/myAfterSale'),
	},
	{
		path: '/myComment',
		name: 'MyComment',
		component: () => import('../views/mine/myComment'),
	},
	{
		path: '/shop',
		name: 'shop',
		component: () => import('../views/shop/shopInfo'),
	},
	{
		path: '/dealerInfo',
		name: 'DealerInfo',
		component: () => import('../views/dealerInfo/dealerList'),
	},
	{
		path: '/addDealer',
		name: 'AddDealer',
		component: () => import('../views/dealerInfo/dealerAdd'),
	},
	{
		path: '/editDealer',
		name: 'EditDealer',
		component: () => import('../views/dealerInfo/dealerEdit'),
	},
	{
		path: '/detailDealer',
		name: 'DetailDealer',
		component: () => import('../views/dealerInfo/dealerDetail'),
	},
	{
		path: '/deliverInfoPay',
		name: 'DeliverInfoPay',
		component: () => import('../views/dealerInfo/dealerPayment'),
	},
	{
		path: '/returnList',
		name: 'ReturnList',
		component: () => import('../views/order/returnList'),
	}, {
		path: '/atpage',
		name: 'atpage',
		component: () => import('../views/atpage/atpage')
		
	}, {
		path: '/goodsReturn',
		name: 'goodsReturn',
		component: () => import('../views/order/goodsReturn'),
	}, {
		path: '/proxyStg',
		name: 'proxyStg',
		component: () => import('../views/proxyStg/proxyStg'),
	}, {
		path: '/customerList',
		name: 'customerList',
		component: () => import('../views/customer/customerList'),
	}, {
		path: '/customerEdit',
		name: 'customerEdit',
		component: () => import('../views/customer/customerEdit'),
	}, {
		path: '/customerChange',
		name: 'customerChange',
		component: () => import('../views/customer/customerChange'),
	},
	{
		path: '/proxyOrderList',
		name: 'proxyOrderList',
		component: () => import('../views/order/orderList')
	},
	{
		path: '/proxyOrderStatistics',
		name: 'proxyOrderList',
		component: () => import('../views/proxyStg/statisticsOrder')
	},
	{
		path: '/proxyClerkList',
		name: 'proxyClerkList',
		component: () => import('../views/proxyStg/proxyClerkList')
	},
	{
		path: '/proxyClerkEdit',
		name: 'proxyClerkEdit',
		component: () => import('../views/proxyStg/proxyClerkEdit')
	},
	{
		path: '/proxyEarning',
		name: 'proxyEarning',
		component: () => import('../views/proxyStg/proxyEarning')
	},{
        path: '/apply',
        name: 'apply',
		component: () => import('../views/apply/apply'),
    },{
        path: '/proxyApply',
        name: 'proxyApply',
		component: () => import('../views/apply/proxyApply'),
    }

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router