import axios from 'axios';
import store from '@/store';
import {
	Dialog,
	Toast
} from "vant";
import _ from '@/common/underscore';
import qy from '@/common/qy';
import storage from '@/common/storage';
import qs from 'qs';
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.transformRequest = [function(data) {

	if (_.isObject(data)) {
		if (data instanceof FormData) {
			return data;
		} else {
			let ret = ''
			for (let it in data) {
				ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
			}
			return ret
		}

	} else {
		return data;
	}

}];
// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: process.env.VUE_APP_BASE_API,
	// 超时
	timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
	config.headers["Proxied-Uid"]=qy.getProxiedUser().userId;
	return config;
}, error => {
	console.log(error)
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {

		// 未设置状态码则默认成功状态
		const code = res.data.code || 200;
		// 获取错误信息
		const msg = res.data.msg;
		if (code === 401) {
			qy.onLogout();
		}
		if (storage.get("silent")) {
			storage.remove("silent");
			return res;
		}
		if (code === 500) {
			Toast(msg)
			return Promise.reject(new Error(msg))
		} else if (code !== 200) {
			Toast(msg)
			return Promise.reject('error')
		} else {
			return res.data
		}
	},
	error => {
		console.log('err' + error)
		let {
			message
		} = error;
		if (message == "Network Error") {
			message = "后端接口连接异常";
		} else if (message.includes("timeout")) {
			message = "系统接口请求超时";
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + message.substr(message.length - 3) + "异常";
		}
		// Toast.fail(message)
		return Promise.reject(error)
	}
)

export default service