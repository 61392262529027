<template>
	<div>
		<div style="position: fixed;top:0;left:0;width: 100%;z-index: 1000;">
			<van-nav-bar :title="title" 
			:left-text="noLeft?'':'返回'" :left-arrow="!noLeft" @click-left="$router.go(-1)" 
			:right-text="noRight?'':'首页'"  @click-right="$router.push('/')"
			/>
		</div>
		<div style="height: 46px;"></div>
	</div>
</template>
<script type="text/javascript">
export default {
    name: "topNav",
    props: ["title","noRight","noLeft"]
}
</script>
<style type="text/css">
	.van-nav-bar__text{color:#333;font-size:15px}
	.van-nav-bar .van-icon{color:#333;font-size:16px;}
	.van-nav-bar__title{font-size:20px;font-weight:600;}
	.van-nav-bar__title{font-size:18px;}
</style>