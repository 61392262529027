import $ from 'jquery';
let defaultConfig = {
	host: "im.51scf.com",
	port: ":18081",
	httpsPort: "/tps",
	debug: false,
	token: "",
	userName: "",
	//断线自动重连
	autoConnect: true,
	onConnectSuccess: null,
	onConnectFailed: null,
	onConnectClosed: null,
	onAcceptMsg: null
};

function init(config) {
	$.extend(this._config, config);
	this._init = true;
	return this;
}
function reConnect(config){
	this.init(config);
	if(this._socket){
		this._socket.close();
	}else{
		this.connect();
	}
}
function connect() {
	let _config = this._config,
		_this = this;
	if (!this._init) {
		this.warn("请先进行初始化操作!")
		return;
	}
	if (this._connect) {
		this.warn("不能重复建立连接");
		return;
	}
	let isHttps = location.href.indexOf('https') == 0;
	let connectUrl = (isHttps ? "wss:" : "ws:") + _config.host + (isHttps ? _config.httpsPort : _config.port) +
		"?userName=" + _config.userName + "&token=" + _config.token;
	let socket = this._socket = new WebSocket(connectUrl);
	socket.onopen = function() {
		_this._onConnectSuccess();
	};
	socket.onerror = function(e) {
		_this._onConnectFailed(e);
	};
	socket.onclose = function() {
		_this._onConnectClosed();
	};
	socket.onmessage = function(event) {
		_this._onAcceptMsg(event);
	}
}

function close() {
	if (this._socket == null) {
		return;
	}
	this._socket.close();

}

function publish(channelName, content) {
	if (!this._connect) {
		this.debug("尚未建立连接!");
		return;
	}
	this._send({
		cmd: 11,
		type: 0,
		chatType: 1,
		msgType: 0,
		content: content,
		groupId: channelName
	});
}

function subscribe(channelConfig) {
	if ($.isEmptyObject(channelConfig) || !channelConfig.channel) {
		console.log("请输入订阅通道");
		return;
	}
	let channelArr = channelConfig.channel.split(",");
	for (let i = 0; i < channelArr.length; i++) {
		let channelName = channelArr[i];
		this._channelMap[channelName] = $.extend({}, channelConfig, {
			status: 0,
			channel: channelName
		});
		this._subscribe(channelName)
	}

}

function JkIm(config) {
	this._config = defaultConfig;
	this.init = init;
	this.connect = connect;
	this.reConnect = reConnect;
	this.close = close;
	this.publish = publish;
	this.subscribe = subscribe;
};
JkIm.prototype = {
	_socket: null,
	_init: false,
	_connect: false,
	_appId: "",
	_channelMap: {},
	_failTimes: 0,
	debug: function(msg) {
		if (this._config.debug) {
			console.log("JkIm debug：" + msg);
		}
	},
	warn: function(msg) {
		console.log("JkIm warn：" + msg);
	},
	_onConnectSuccess: function() {
		this.debug("已建立连接!");
		this._connect = true;
		this._failTimes = 0;
		this._autoSubscribe();
		this._callback(this._config.onConnectSuccess);
	},
	_onConnectFailed: function(e) {
		this.debug("建立连接失败!errMsg:" + e);
		this._callback(this._config.onConnectFailed);
	},
	_onConnectClosed: function() {
		this._connect = false;
		for (let channelName in this._channelMap) {
			this._channelMap[channelName].status = 0;
		}
		this._callback(this._config.onConnectClosed);
		this._socket = null;
		this.debug("连接已关闭!");
		if (this._config.autoConnect) {
			this._startConnectTask();
		}
	},
	_callback: function(func, arg1, arg2, arg3) {
		if ($.isFunction(func)) {
			func(arg1, arg2, arg3);
		}
	},
	_startConnectTask: function() {
		let _this = this;
		this._failTimes++;
		if (this._failTimes > 60) {
			return;
		}
		setTimeout(function() {
			_this.warn("正在进行第" + _this._failTimes + "次重连...");
			_this.connect();
		}, 5 * 1000);
	},
	_autoSubscribe: function() {
		for (let channelName in this._channelMap) {
			let channelInfo = this._channelMap[channelName];
			if (channelInfo.status == 0) {
				this._subscribe(channelName);
			}
		}
	},
	_restoreChannel: function(groupId) {
		if (!groupId) {
			return null;
		}
		return groupId.substring(groupId.indexOf("-") + 1);
	},
	_onAcceptMsg: function(e) {
		let resp = JSON.parse(e.data);
		this.debug(e.data);
		let chatData = resp.data;
		if (resp.command == 11) { //聊天请求
			if (chatData.chatType == 1) { //广播
				let channel = this._restoreChannel(chatData.groupId);
				this._handlePubMsg({
					channel: channel,
					content: chatData.content
				});
			} else {
				this._callback(this._config.onMessage, chatData.content);
			}
		} else if (resp.command == 4) {
			this._appId = resp.data.appId;
		} else if (resp.command == 8) {
			this._onSubscribeSuccess(resp.group);
		}
	},
	_onSubscribeSuccess: function(channel) {
		let channelInfo = this._channelMap[channel];
		if (channelInfo) {
			channelInfo.status = 1;
			this._callback(channelInfo.onSuccess, channel);
		}
	},
	_handlePubMsg: function(message) {
		let channelConfig = this._channelMap[message.channel];
		if (channelConfig) {
			this._callback(channelConfig.onMessage, message);
		}
	},
	_subscribe: function(channelName) {
		if (!this._connect) {
			return;
		}
		this._send({
			cmd: 7,
			type: 0,
			groupId: channelName
		});
	},
	_send: function(content) {
		if (!this._connect) {
			this.warn("尚未建立连接!");
			return;
		}
		this._socket.send(JSON.stringify(content));
	}
}
let ImInstance = new JkIm();
export default ImInstance;