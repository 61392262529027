import _ from "./underscore";
import {Toast,Dialog} from 'vant';
import storage from '@/common/storage.js';
import mapUtil from '@/common/mapUtil.js';
import ImUtil from '@/common/ImUtil.js';
import router from '@/router/router.js';
import request from '@/utils/request';
var _userPerms = null;
export default {
	formatMoney: function(amt,defaultMsg) {
		try {
			defaultMsg=defaultMsg||"0.00";
			var s = parseFloat(amt) + "";
			if (s === "NaN") {
				return defaultMsg;
			}
			if(s==0){
				return defaultMsg;
			}
			var dotIndex = s.indexOf(".");
			if (dotIndex > -1) {
				var suffix = s.substr(dotIndex + 1, 2);
				for (var i = suffix.length; i < 2; i++) {
					suffix += "0";
				}
				return s.substring(0, dotIndex + 1) + suffix;
			} else {
				return s + ".00";
			}
		} catch (e) {
			return defaultMsg;
		}
	},
	add: function(arg1, arg2) {
		var r1, r2, m, rs, maxLen;
		try {
			r1 = arg1.toString().split(".")[1].length
		} catch (e) {
			r1 = 0
		}
		try {
			r2 = arg2.toString().split(".")[1].length
		} catch (e) {
			r2 = 0
		}
		maxLen = Math.max(r1, r2);
		m = Math.pow(10, maxLen);
		rs = (arg1 * m + arg2 * m) / m + "";
		try {
			var arr = rs.toString().split(".");
			if (arr[1].length > maxLen) {
				rs = arr[0] + "." + arr[1].substring(0, maxLen);
			}
		} catch (e) {

		}
		return parseFloat(rs);
	},
	divide: function(n1, n2, len) {
		var rs = n1 / n2 + "",
			len = len || 2;
		var arr = rs.split(".");
		if (arr[1] && arr[1].length > len) {
			return arr[0] + "." + arr[1].substring(0, len);
		}
		return rs;
	},
	getUrlParam: function(name,defalutValue) {
		if(defalutValue===undefined){
			defalutValue="";
		}
	    if (!name) {
	        return this.getUrlParamObj()||defalutValue;
	    }
	    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	    var r = window.location.search.substr(1).match(reg);
	    if (r != undefined) {			
	        return decodeURI(r[2]);
	    } else {
	        return defalutValue;
	    }
	},
	getUrlParamObj: function() {
	    let o = {},
	        s = location.search.substring(1);
	    if (s) {
	        this.each(s.split("&"), function(i, p) {
	            let arr = p.split("=");
	            o[arr[0]] = decodeURI(arr[1]);
	        });
	    }
	    return o;
	
	},
	convertImgUrl: function(s) {
	    if (!s || s.indexOf("http") != 0) {
	        return s;
	    }
	    let arr = s.split("\/");
	    let relativeUrl = arr.slice(3).join("/");
	    return "http://" + location.hostname + (":" + (location.port || 80)) + _prefix + relativeUrl;
	},
	getImgUrl(path) {
	    if (path && path.indexOf("http") < 0) {
	        return "/api/" + path;
	    }
		return path;
	},
	serialize: function(data, prefix) {
	    var _this = this,
	        prefix = prefix || "";
	    if (!data) {
	        return "";
	    }
	    var isFirst = true,
	        rs = "";
	    for (let key in data) {
	        if (isFirst) {
	            isFirst = false;
	        } else {
	            rs += "&";
	        }
	        if (_.isArray(data[key])) {
	            rs += _this.searializeArr(data[key], key);
	        } else if (_.isObject(data[key])) {
	            rs += _this.serialize(data[key], prefix + key + ".");
	        }  else {
	            rs += prefix + key + "=" + (data[key] === null || data[key] === undefined ? "" : data[key]);
	        }
	    }
	    return rs;
	},
	
	searializeArr: function(arr, prefix) {
	    var _this = this,
	        rs = "",
	        prefix = prefix || "";
	    _.each(arr, function(item,i) {
	        if (i > 0) {
	            rs += "&";
	        }
	        if (_.isArray(item)) {
	            rs += _this.searializeArr(item, prefix + "[" + i + "]");
	        } else if (_.isObject(item)) {
	            rs += _this.serialize(item, prefix + "[" + i + "].");
	        }  else {
	            rs += prefix + "[" + i + "]=" + item;
	        }
	    });
	    return rs;
	},
	addCartNum(goodsInfo,num){
		let isLogin=this.checkLogin();
		if(!isLogin){
			return;
		}
		if(num<0&&goodsInfo.cartNum<=0){
			return;
		}
		
		goodsInfo.cartNum=goodsInfo.cartNum+num;
		if(goodsInfo.stockNum<goodsInfo.cartNum){
			goodsInfo.cartNum=goodsInfo.stockNum;
			Toast("库存不足!");
			return;
		}
		if(goodsInfo.maxBuyNum>0&&goodsInfo.maxBuyNum<goodsInfo.cartNum){
			goodsInfo.cartNum=goodsInfo.maxBuyNum;
			return;
		}
		if(goodsInfo.startBuyNum>goodsInfo.cartNum){
			if(num<0){
				goodsInfo.cartNum=0;
			}else{
				goodsInfo.cartNum=goodsInfo.startBuyNum;
			}
		}
		
		Toast.loading();
		request.post("/api/shopCart/edit", {
		    goodsInstanceId: goodsInfo.instanceId,
		    goodsNum: goodsInfo.cartNum,
		    isPlus: false
		}).then((rs)=>{
			Toast.clear();
			if(this.onUpdateShopingCartSuccess){
				this.onUpdateShopingCartSuccess();
			}
		})
	},
	getInstanceDesc(goods,containsBuyInfo){
		if(!goods){
			return ""; 
		}
		let arr=[];
		for(let i=1;i<=4;i++){
			if(goods["param"+i+"Val"]){
				arr.push(goods["param"+i+"Val"])
			}else{
				break;
			}
		}
		let str=arr.join(" | ");
		if(containsBuyInfo){
			if(!goods.startBuyNum||goods.startBuyNum==1){
				
			}else{
				str+=" | "
				str+=(goods.startBuyNum||1)+goods.goodsUnit+"起购";
			}
			if(goods.maxBuyNum){
				str+=" | 限购"+goods.maxBuyNum;
			}
		}
		return str;
		
	},
	navigateTo(url, param) {
		this.$router.push({
			path: url,
			query: param
		})
	},
	redirectTo(url, param) {
		this.$router.push({
			path: url,
			redirect: true,
			query: param
		})
	},
	checkLogin(navToIndex) {
		if (this.isLogin()) {
			return true;
		}
		let curPageRoute = this.getPageUrl();
		this.setData("loginPrevPage", curPageRoute);
		Dialog.confirm({
			message: "请先登录",
			confirmButtonText: '立即登录',
			cancelText: navToIndex ? "返回首页" : '取消',
			type: 'warning'
		}).then(() => {
			storage.set("loginFrom", location.pathname);
			this.navigateTo("/smsLogin");
		}).catch(() => {
			if (navToIndex) {
				this.navigateTo('/')
			}
		});

		return false;
	},
	isLogin() {
		return this.getData("isLogin") == true;
	},
	getCurPage() {
		return router.history.current;
		// let curPages = getCurrentPages();
		// return curPages[curPages.length - 1];
	},
	getData(name,defaultValue) {
		let value = storage.get(name);
		if(value===undefined){
			value=defaultValue;
		}
		try {
			return JSON.parse(value);
		} catch (e) {
			return value;
		}
	},
	setData(key, val) {
		try{
			storage.set(key, JSON.stringify(val));
		}catch(e){
			storage.set(key, val);
		}
	},
	removeData(key) {
		storage.remove(key);
	},
	getUserInfo(key) {
		let userInfo = this.getData("userInfo") || {};
		return key ? (userInfo[key] || "") : userInfo;
	},
	getUserId() {
		return this.getUserInfo("userId");
	},
	setOpenId(openId) {
		this.setData("openId", openId);
	},
	getOpenId() {
		return this.getData("openId");
	},

	getUserPhone() {
		return this.getUserInfo("phone");
	},
	getUserName() {
		return this.getUserInfo("userName");
	},
	getUserType() {
		return this.getUserInfo("userType");
	},
	onLoginSuccess(userInfo) {
		userInfo.userId = userInfo.userid;
		this.setData("isLogin", true);
		this.setData("userInfo", userInfo);
		let loginPrevPage = this.getData("loginPrevPage");
		_userPerms = userInfo.menuPerms;
		this.setData("_userPerms", userInfo.menuPerms);
		this.removeData("loginPrevPage");
		this.redirectTo(loginPrevPage || "index");
		ImUtil.connect();
	},
	onLogout(toHome) {
		this.setData("isLogin", false);
		this.setData("userInfo", null);
		this.removeData("_userPerms");
		this.removeData("proxiedUser");
		if(toHome){
			this.redirectTo("/");
		}
		ImUtil.connect();
	},
	isFunction(o) {
		return this._checkType(o, "Function");
	},
	isPlainObject(o) {
		return this._checkType(o, "Object");
	},
	isArray(o) {
		return this._checkType(o, "Array");
	},
	isString(o) {
		return this._checkType(o, "String");
	},
	_checkType(source, type) {
		let typeName = "[object " + type + "]";
		return Object.prototype.toString.call(source) == typeName;
	},
	toast(msg, callback) {
		Toast(msg);
		if (this.isFunction(callback)) {
			setTimeout(callback, 1000);
		}
	},
	toastSuccess(msg, callback) {
		Toast.success(msg);
		if (this.isFunction(callback)) {
			setTimeout(callback, 1000);
		}
	},
	alert(msg, callback) {
		Dialog({
			message: msg
		}).then(callback);
	},
	confirm(title, msg, onConfirm, onCancel) {
		if(this.isFunction(msg)){
			onCancel=onConfirm;
			onConfirm=msg;
			msg=title;
			title="提示";
		}
		Dialog.confirm({
			title: title,
			message: msg,
		}).then(onConfirm).catch(onCancel);
	},
	date: {
		format(d, noSecond) {
			if (typeof d != "date") {
				d = new Date(d);
			}
			let str = this.formatDate(d) + " ";
			let hours = d.getHours();
			str += (hours < 10 ? '0' : '') + hours + ":";
			let minute = d.getMinutes();
			str += (minute < 10 ? '0' : '') + minute;
			if (!noSecond) {
				src += ":"
				let second = d.getSeconds();
				str += (second < 10 ? '0' : '') + second;
			}
			return str;
		},
		formatTime1(d) {
			return this.format(d, true)
		},
		formatDate(d) {
			if (typeof d != "date") {
				d = new Date(d);
			}
			let month = d.getMonth() + 1;
			let date = d.getDate();
			return d.getFullYear() + "-" + (month < 10 ? ('0' + month) : month) + "-" + (date < 10 ? ('0' + date) :
				date);
		},
		now() {
			return this.format(new Date());
		},
		today() {
			return this.formatDate(new Date());
		},
		yestoday() {
			return this.formatDate(this.diffDay(-1));
		},
		diffDay(beginDate, diffNum) {
			if (!diffNum) {
				diffNum = beginDate;
				beginDate = new Date();
			} else {
				beginDate = new Date(beginDate);
			}
			return new Date(beginDate.getTime() + diffNum * 24 * 3600 * 1000);
		},

		diffYear(beginDate, diffNum) {
			if (!diffNum) {
				diffNum = beginDate;
				beginDate = new Date();
			} else {
				beginDate = new Date(beginDate);
			}
			beginDate.setFullYear(beginDate.getFullYear() + diffNum);
			return beginDate;
		}
	},
	dictList: {
		meterType: [{
				value: "1",
				label: "电表"
			},
			{
				value: "2",
				label: "水表"
			}
		]
	},
	getDictValue(type, label) {
		return this._doGetDict(type, label, false, "");
	},
	getDict(type, value) {
		if(this.isArray(type)){
			for (let i = 0; i < type.length; i++) {
				if(type[i].value==value||type[i].dictValue==value){
					return type[i].label||type[i].dictLabel;
				}
			}
			return "";
		}
		return this._doGetDict(type, value, true, "");
	},
	_doGetDict(type, value, byVal, defaultValue) {
		let dict = this.dictList[type];
		if (!dict) {
			return "type不存在";
		}
		for (let i = 0; i < dict.length; i++) {
			if (dict[i][byVal ? 'value' : 'label'] == value) {
				return dict[i][!byVal ? 'value' : 'label'];
			}
		}
		return defaultValue;
	},
	getWinHeight() {
		return window.innerHeight;
	},
	getWinWidth() {
		return window.innerWidth;
	},
	hasPerm(permName) {
		if (!permName) {
			return true;
		}
		if (!_userPerms || _userPerms.length < 1) {
			_userPerms = this.getData("_userPerms") || [];
		}
		for (let i = 0; i < _userPerms.length; i++) {
			if (_userPerms[i] == permName) {
				return true;
			}
		}
		return false;
	},
	//数组转字符串，以逗号隔开
	arrayToStr(paramArray) {
		let retStr = "";
		for (let i = 0; i < paramArray.length; i++) {
			if (retStr == "") {
				retStr = paramArray[i];
			} else {
				retStr = retStr + "," + paramArray[i];
			}
		}
		return retStr;
	},
	getPageUrl() {
		return this.$router.currentRoute.fullPath;
	},
	reloadPage() {
		this.$router.go(0);
	},
	navigateBack() {
		this.$router.go(-1);
	},
	doGet(url, data, callback) {
		if(this.isFunction(data)){
			callback=data;
			data={};
		}
		request.get(url, data).then(callback);
	},
	doPost(url, data, callback) {
		if(this.isFunction(data)){
			callback=data;
			data={};
		}
		request.post(url, data).then(callback);
	},
	isProxyManager(){
		return this.isLogin()&&(this.getUserType()=="03");
	},
	isProxy(){
		return this.isLogin()&&(this.getUserType().indexOf("03")==0);
	},
	getProxiedUser(){
		let nullProxyInfo={userId:"",userName:""};
		
		if(this.isProxy()){
			return this.getData("proxiedUser",nullProxyInfo);
		}else{
			return nullProxyInfo;
		}
	},
	each(arr,callback){
		if(!arr||!this.isFunction(callback)){
			return;
		}
		for(let i=0;i<arr.length;i++){
			callback(i,arr[i])
		}
	},
	refreshLocation(BMapObj,callback){
		let lastLocation = this.getData('_lastLocation');
		if (lastLocation) {
			let diffMinute = (new Date().getTime() - lastLocation.time) / 1000 / 60;
			if (diffMinute < 3) {
				console.log("使用缓存的位置数据");
				if (callback) {
					callback(lastLocation.data);
				}
				return;
			}else{
				this.doRefreshLocation(BMapObj,callback);
			}
		}else{
			this.doRefreshLocation(BMapObj,callback);
		}
		
	},
	doRefreshLocation(BMapObj,callback){
		if(!BMapObj){
			console.warn("百度地图对象为空!");
			return ;
		}
		const geolocation = new BMapObj.Geolocation()
		geolocation.enableSDKLocation();
		// 通过 getCurrentPosition() 方法：获取当前的位置信息
		geolocation.getCurrentPosition(res => {
		  // console.log('调用百度API返回当前位置结果:', res)
		  if(!res.latitude){
			  console.warn("百度地图获取定位失败!");
			  return;
		  }
		  // res.point=mapUtil.gcg02ToBd09(res.latitude,res.longitude)
		  
		  this.setData('_lastLocation', {
		  	time: new Date().getTime(),
		  	data: res
		  });
		  callback(res);
		})
	}
}