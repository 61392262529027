import storage from '@/common/storage.js';
import JKIm from '@/common/JkIm.js';
import qy from '@/common/qy.js';
import {Toast,Dialog} from 'vant';
export default {
	connect() {
		JKIm.reConnect({
			token: "DETACMKDMGMROUORIIGT",
			userName: qy.getUserId(),
			onMessage:this.onMessage,
			debug: false
		})
	},
	onMessage(originMsg){
		try{			
			originMsg=decodeURIComponent(originMsg);
			let data=JSON.parse(originMsg);
			if(data.type=="orderTransfer"){
				Toast({
					message: data.msg,
					duration:3000,
					position: 'top'
				});
				let curPage=qy.getCurPage();
				// console.log(curPage)
				if(curPage.path.indexOf("orderDetail")>-1&&data.orderId==curPage.query.orderId){
					setTimeout(function(){
						location.reload();
					},3000)
				}
			}
		}catch(e){
			console.log(e)
			console.log("消息处理异常:"+originMsg)
		}
	}
}